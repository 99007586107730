"use client";

import { $getRoot, LexicalEditor } from "lexical";
import { forwardRef, useRef, useState } from "react";
import { Button, ButtonSize } from "~/scalis-components/core";
import { Body, BodySize, BodyType } from "~/scalis-components/core/typography";
import { cn } from "~/utils/cn";

import { $generateHtmlFromNodes } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";

import { ClearContent } from "../clear-content";
import { ControlDisabled } from "../control-disabled";
import { LoadInitialContent } from "../load-initial-content";
import { ToolbarPlugin } from "../toolbar";
import ExampleTheme from "./config";
import { inputEditorClassName } from "./editor.styles";
import { EditorProps } from "./editor.types";

const editorConfig: InitialConfigType = {
  namespace: "Scalis",
  theme: ExampleTheme,
  onError(error: unknown) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

export const Editor = forwardRef<LexicalEditor | undefined, EditorProps>(
  (
    {
      label,
      initialContent,
      setValue,
      className,
      disabled = false,
      placeholder,
      autoFocus = false,
      contentClassName,
      placeholderClassName,
      hidePlaceHolderOnFocus = false,
      onChange: propsOnChange,
      toolbarDivider = false,
      hideToolbar = false,
      value,
      error,
      name,
      innerContainerClassName,
      interpolationFeatureFlag,
      onSubmit,
      onSubmitLoading,
    },
    ref,
  ) => {
    const usedValueProps = !!value;
    const isMounted = useRef(false);
    const [focused, setFocused] = useState(false);

    const onChange = (editor: LexicalEditor) => {
      editor.update(() => {
        const root = $getRoot();

        if (!isMounted.current && usedValueProps) {
          root.clear();
          isMounted.current = true;
        }

        const firstChild = root.getFirstChild();
        if (!firstChild && usedValueProps) {
          setValue?.("", "");
          propsOnChange?.({ target: { value: "", name } });
          return;
        }

        const htmlString = $generateHtmlFromNodes(editor, null);

        setValue?.(name ?? "", htmlString);
        propsOnChange?.({ target: { value: htmlString, name } });
      });
    };

    const { containerStyles, headerStyles, placeholderStyles, richTextStyles } =
      inputEditorClassName({
        disabled,
        error: !!error,
        hideToolbar,
      });

    return (
      <div>
        {label && (
          <Body size={BodySize.small} type={BodyType.basic}>
            {label}
          </Body>
        )}

        <LexicalComposer
          initialConfig={{ ...editorConfig, editable: !disabled }}
        >
          <div className={containerStyles({ class: className })}>
            <div className={headerStyles()}>
              <ToolbarPlugin
                disabled={disabled}
                interpolationFeatureFlag={interpolationFeatureFlag}
              />
            </div>

            <div className={cn("relative", innerContainerClassName)}>
              <RichTextPlugin
                placeholder={
                  <div
                    className={cn(
                      placeholderStyles({ class: placeholderClassName }),
                      {
                        invisible: (hidePlaceHolderOnFocus && focused) || value,
                      },
                    )}
                  >
                    {placeholder}
                  </div>
                }
                contentEditable={
                  <ContentEditable
                    className={richTextStyles({ class: contentClassName })}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                  />
                }
                ErrorBoundary={LexicalErrorBoundary}
              />

              <LoadInitialContent initialContent={initialContent} />

              <HistoryPlugin />
              {autoFocus && <AutoFocusPlugin />}
              <ListPlugin />
              <LinkPlugin />
              <OnChangePlugin
                onChange={(editorState, editor) => onChange(editor)}
              />

              {usedValueProps && <ClearContent value={value} />}
              <ControlDisabled disabled={disabled} />

              {onSubmit && (
                <Button
                  className="absolute bottom-2 right-2"
                  size={ButtonSize.small}
                  onClick={() => onSubmit(value as any)}
                  loading={onSubmitLoading}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </LexicalComposer>
      </div>
    );
  },
);
